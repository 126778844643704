<template>
  <div :class="['location karte', 'page-content']">
    <div class="location-top d-flex justify-content-between d-lg-none">
      <div class="btns-group">
        <button @click="showLocationList"  :class="['btn btn-sm']">Liste</button>
        <button :class="['btn btn-sm active']">Karte</button>
      </div>

      <button class="btn btn-simple btn-icon filter-btn" @click="filterDropdown = !filterDropdown">
        <span v-if="this.selectedCategoriesCount>0" class="dot">{{selectedCategoriesCount}}</span>Filter
        <span class="material-icons">{{ filterDropdown ? 'expand_less' : 'expand_more' }}</span>
      </button>
    </div>
    
    <div v-if="filterDropdown" class="filter-dropdown d-lg-none">
      <filter-options :dropdown="true" @changedSelectedCategories="changedSelectedCategories" @changedSelectedDestination="changedSelectedDestination" @closeThis="closeThis"/>
    </div>

    <div :class="['sidebar', 'pb-0', filter ? 'filter-active': '']">
      <form action="#!" class="clearfix d-none d-lg-block">
        <label>Locations</label>
        <destination-select v-if="false" @changedSelectedDestination="changedSelectedDestination"/>

        <button class="btn btn-simple btn-icon float-right filter-btn" type="button" @click="filter = !filter">
          <span v-if="this.selectedCategoriesCount>0" class="dot">{{selectedCategoriesCount}}</span>Filter 
          <span class="material-icons">{{ !filter ? 'expand_more' : 'expand_less'}}</span>
        </button>
        <div class="clearfix"></div>
        <filter-options v-if="filter" @changedSelectedCategories="changedSelectedCategories" @changedSelectedDestination="changedSelectedDestination" @closeThis="closeThis"/>
      </form>
      <hr>

      <div class="sidebar__scrolling mt-0" v-if="data">
       <section class="section" style="background:none;">
        <div class="row card-sections mobile-scrolling" v-if="dataSet.meta!=null" tabindex="-1">
          <div v-for="(content,index) in dataSet.data" :key="content.id" :data-num="index + 1" class="mt-auto">
            <LocationCard :id="'content-'+content.id" :class="[isFirst(index), isLast(index)]" :item="content" :marginRight="'0px;'" :disableLink="true" :desktopAuto="desktopAuto" :hideFullContent="true" @highlight="highlightThisContent"/>
          </div>
        </div>
      </section>
    </div>
  </div>

  <multiple-map class="map" ref="theMap" :initialCoords="storedDestination.coords" :contents="data" @highlight="highlightThisContent"></multiple-map>

  <!-- Footer Margin -->
  <div class="footer-margin"></div>
</div>
</template>

<script>
  import { latLng } from 'leaflet';
  import VueScrollTo from 'vue-scrollto';
  import { getTripmeisterLocations } from '@/api/content';
  import { screenSizeMixin } from '@/mixins/screenSizeMixin';
  import { showLoader, hideLoader, sortByProperty, getFieldValues } from '@/utils/helpers';

  export default {
    name: 'LocationsKarte',
    mixins: [screenSizeMixin],
    components: {
      LocationCard: () => import('@/components/cards/LocationCard'),
      FilterOptions: () => import('@/components/FilterOptions'),
      MultipleMap: () => import('@/components/map/multiple.vue'),
      DestinationSelect: () => import('@/components/controls/DestinationSelect'),
      BackButton: () => import('@/components/controls/BackButton')
    },
    data() {
      return {
        liste: true,
        filter: false,
        loader: null,
        filterDropdown: false,
        karte:{
          center: null,
          zoom: null,
        },
        dataSet: {
          total: 0,
          data: null,
          meta:null,
          query: {
            page:1,
            limit: 450,
            keyword: '',
            type: 'view',
            onlyWithLocation: true,
            resourceType: 'view',
            selectedLatLng: {
              point: null,
              distance: this.$radius,
            },
            selectedAppId: this.$appId,
            sort: {
              prop: 'title',
              order: 'ascending'
            },
          },
        },
        scrollVerticalOptions: {
          container: '.sidebar__scrolling',
          easing: 'ease-in',
          lazy: false,
          offset: -60,
          force: true,
          cancelable: true,
          x: false,
          y: true,
        },
        scrollHorizontalOptions: {
          container: '.sidebar__scrolling',
          easing: 'ease-in',
          lazy: false,
          offset: -60,
          force: true,
          cancelable: true,
          x: true,
          y: false,
        },
        category: null,
      }
    },
    created() {
      /*check if there are any preset query params*/
      console.log(this.$route.query);
      if(Object.prototype.hasOwnProperty.call(this.$route.query,'categories')){
        this.dataSet.query.keyword=decodeURIComponent(this.$route.query.categories);
      }
      if(Object.prototype.hasOwnProperty.call(this.$route.params,'destination')){
        var destination=decodeURIComponent(this.$route.params.destination);
        /*we're going to iterate through all the destinations and try to find the right one*/
        for(var i=0;i<this.destinations.length;i++){
          var dest = this.destinations[i];
          if(dest.name == destination){
            this.$store.commit('SET_SELECTED_DESTINATION',dest);
            break;
          }
        }
      }

      if(this.dataSet.data==null){
        this.getContents();
      }

    },
    watch: {
      storedDestination: function(val) {
        this.karte.zoom = val.zoom;
        this.karte.center = val.coords;
        if(this.loader == null || (Object.prototype.hasOwnProperty.call(this.loader,"data") && Object.prototype.hasOwnProperty.call(this.loader.data,"isActive") && this.loader.data.isActive==false)){
          this.getContents();
        }
      },
    },
    computed: {
      selectedCategoriesCount(){
        if(this.$store.getters.getSelectedCategories !== null){
          return this.$store.getters.getSelectedCategories.length;
        }
      },
      desktopAuto(){
        if(this.$isMobileScreen){
          return false;
        }
        return true;
      },
      data(){
        if(this.dataSet.data!=null && this.dataSet.data.length>0){
          return this.dataSet.data;
        }
        return null;
      },
      destinations(){
        return this.$store.getters.getDestinations;
      },
      storedDestination(){
        return this.$store.getters.getSelectedDestination;
      },
    },
    methods: {
      showLoader,
      hideLoader,
      async getContents() {
        this.loader = this.showLoader(this.loader);
        const { limit, page } = this.dataSet.query;
        /*set the center point for this location*/
        this.dataSet.query.selectedLatLng.point=latLng(this.storedDestination.coords.lat,this.storedDestination.coords.long);
        const { data, meta } = await getTripmeisterLocations(this.dataSet.query);
        this.dataSet.data = data;
        this.dataSet.data.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1;
        });
        this.dataSet.meta = meta;
        this.dataSet.total = meta.total;
        this.loader = this.hideLoader(this.loader);
      },
      isFirst(index){
        if(this.$isMobileScreen){
          return index == 0?'first-elem':'';
        }
        return '';
      },
      isLast(index){
        if(this.$isMobileScreen){
          if(this.dataSet.data.length == (index+1)){
            return 'last-elem';
          }
          return '';
        }
      },
      highlightThisContent(params){
        Object.keys(this.dataSet.data).forEach((k) => {
          this.dataSet.data[k].highlight = false;
          if(this.dataSet.data[k].id == params.content.id){
            this.dataSet.data[k].highlight = true;

            if(params.sender != "map"){
              /*open the popup*/
              this.$refs.theMap.openPopup(params.content);
            }
            else{
             if(this.$isMobileScreen){
              VueScrollTo.scrollTo(('#content-'+this.dataSet.data[k].id), '2000',this.scrollHorizontalOptions);
            }
            else{
              VueScrollTo.scrollTo(('#content-'+this.dataSet.data[k].id), '2000',this.scrollVerticalOptions);
            }
          }
        }
      });
      },
      changedSelectedCategories(categories) {
        /*reload results*/
        this.dataSet.query.keyword = categories;
        this.getContents();
      },
      changedSelectedDestination(destination){
        /*reload results*/   
        this.getContents();
        this.$router.push({ name: 'LocationKarte', params: {destination: encodeURIComponent(this.storedDestination.name) } });
      },
      closeThis(){
        this.filter = false;
        this.filterDropdown = false;
      },
      showLocationList() {
        this.$router.push({ name: 'LocationIndex', params: {destination: encodeURIComponent(this.storedDestination.name) } });
      },
    }
  }
</script>

<style lang="scss">

  .filter-active {
    padding-bottom: 200px !important;
  }

</style>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .filter-btn {
    padding-left: 17px;
  }

  .dot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    width: 15px;
    background-color: $primary;
    color: #FFF;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    left: 0px;
    font-size: 11px;

    @media(max-width: 991px){
      bottom: 0;
      top: 10px;
    }
  }
</style>
